import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject, filter } from 'rxjs';
import { catchError, debounceTime, map } from 'rxjs/operators';
import { Config } from '@core/service/config';
import { AppStorageService } from '@shared/service/storage/storage.service';
import { LoginServiceInjection } from '@shared/service/methods/auth/login';
import { LogoutServiceInjection } from '@shared/service/methods/auth/logout';
import { CheckServiceInjection } from '@shared/service/methods/auth/check';

export interface User {
    login?: string | null;
    name?: string | null;
    logo_url?: string | null;
}
export interface Permissions {
    permissions?: string[] | null;
}

@Injectable({
    providedIn: 'root',
})
export class Auth {
    private _router = inject(Router);
    private _activatedRoute = inject(ActivatedRoute);
    private _config = inject(Config);
    private _storageService = inject(AppStorageService);
    private _apiAuthCheckService = inject(CheckServiceInjection);
    private _apiAuthLoginService = inject(LoginServiceInjection);
    private _apiAuthLogoutService = inject(LogoutServiceInjection);

    private nameAuthToken = 'auth_token';
    private defaultUrl = this._config.get('startUrl');
    private backUrlSelector = 'backUrl';
    private ignoreBackUrl = ['/auth', '/', '/logout'];

    public user$: BehaviorSubject<User> = new BehaviorSubject<User>({});
    public permissions$: BehaviorSubject<Permissions> =
        new BehaviorSubject<Permissions>({});
    public baclUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(
        this.defaultUrl
    );

    constructor() {
        this._router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd &&
                        !this.ignoreBackUrl.includes(
                            event.url.replace(/\?.*/gi, '')
                        )
                ),
                // чтобы не срабатывал на промежуточные редиректы и установку параметров по умолчанию
                debounceTime(100)
            )
            .subscribe(() => {
                this.baclUrl$.next(this._router.routerState.snapshot.url);
            });
    }

    /**
     * Авторизация
     *
     * @param {string} login
     * @param {string} password
     *
     */
    login(login: string, password: string) {
        return this._apiAuthLoginService
            .login({
                login: login.trim().toLowerCase(),
                password: password.trim(),
            })
            .pipe(
                map((res) => {
                    if (res.auth_token) {
                        this.setToken(res.auth_token);
                    }
                    this._router.navigateByUrl(this.getBackUrl());

                    return res;
                })
            );
    }

    /**
     * Выход
     *
     **/
    logout(): void {
        this._apiAuthLogoutService
            .logout()
            .subscribe(() => {})
            .add(() => {
                this.delToken();
                this.user$.next({ login: null, name: null });
                this.permissions$.next({ permissions: null });
                this.goToAuth();
            });
    }

    /**
     * Запись авторизационного токена
     *
     */
    private setToken(token: string): void {
        if (token.length === 64) {
            this._storageService.setItem(this.nameAuthToken, token);
        }
    }

    /**
     * Возращает авторизационный токен
     *
     */
    private getToken(): string {
        return <string>this._storageService.getItem(this.nameAuthToken);
    }

    /**
     * Удаление авторизационного токена
     *
     */
    private delToken(): void {
        this._storageService.removeItem(this.nameAuthToken);
    }

    /**
     * Проверка авторизационного токена и прав к разделу
     *
     */
    check(permissionId?: string): Observable<boolean> {
        if (this.getToken()) {
            return this._apiAuthCheckService.checkauth().pipe(
                map((v) => {
                    if (v.login) {
                        const tmp: User = { login: v.login, name: v.name };
                        if (v.logo_url) {
                            tmp.logo_url = v.logo_url;
                        }
                        this.user$.next(tmp);
                        this.permissions$.next({ permissions: v.permissions });
                        if (this.checkPermission(permissionId)) {
                            return true;
                        } else {
                            if (permissionId === 'auth') {
                                this._router.navigateByUrl(this.getBackUrl());
                            }
                            return false;
                        }
                    } else {
                        this.logout();
                        return false;
                    }
                }),
                catchError((res) => {
                    this.logout();
                    return of(res);
                })
            );
        } else {
            this.goToAuth();
            return of(false);
        }
    }

    /**
     * Проверка прав доступа
     *
     */
    checkPermission(permissionId: string = ''): boolean {
        const permissions = this.permissions$.getValue().permissions;
        if (
            (permissions && permissions.indexOf(permissionId) >= 0) ||
            permissionId === 'everyone'
        ) {
            return true;
        }
        return false;
    }

    /**
     * Возвращает путь для возврата после авторизации
     *
     */
    private getBackUrl(): string {
        if (
            this._activatedRoute.snapshot.queryParamMap.has(
                this.backUrlSelector
            )
        ) {
            return this._activatedRoute.snapshot.queryParamMap.get(
                this.backUrlSelector
            )!;
        } else {
            return this.baclUrl$.value;
        }
    }

    private goToAuth() {
        this._router.navigate(['/auth'], {
            queryParams: { backUrl: this.getBackUrl() },
            queryParamsHandling: 'merge',
        });
    }
}
